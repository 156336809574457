import React from "react";
import Topbar from "../components/Topbar";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';

function HomePage() {
  return (
    <div>
      <CssBaseline />
      <Topbar />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh',
          padding: '20px',
        }}
      >
        <main
          style={{
            marginTop: '25vh', 
            width: '100%', 
            textAlign: 'center', 
          }}
        >
          <h1 style={{ fontSize: '3rem' }}>Jae Park</h1>
          <p style={{ marginTop: '-25px' }}>Student, software engineer, and aspiring writer.  </p>
          <p> </p>
        </main>

      </Container>
      </div>
  );
}

export default HomePage;
