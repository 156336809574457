import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import Topbar from "./Topbar";
import ReactMarkdown from 'react-markdown';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';
const projects = [
  { 
    id: 1, 
    title: 'E-Commerce Website Development', 
    date: 'January 15, 2023', 
    author: 'Jae Park',
    content: '### Developed a fully functional e-commerce website with user authentication, product listings, and a shopping cart. Utilized technologies such as React, Node.js, and MongoDB to create a seamless shopping experience.'
  },
  { 
    id: 2, 
    title: 'Mobile Fitness App', 
    date: 'February 10, 2023', 
    author: 'Jae Park',
    content: 'Created a mobile application that tracks fitness activities, provides workout plans, and integrates with wearable devices. The app was developed using React Native and Firebase for real-time data synchronization.'
  },
  { 
    id: 3, 
    title: 'Social Media Analytics Tool', 
    date: 'March 20, 2023', 
    author: 'Jae Park',
    content: 'Built a web application to analyze and visualize social media data, helping businesses improve their online presence. The tool leverages Python for data analysis and visualization libraries such as D3.js for interactive charts.'
  },
  { 
    id: 4, 
    title: 'Personal Finance Management System', 
    date: 'April 5, 2023', 
    author: 'Jae Park',
    content: 'Developed a system for tracking expenses, creating budgets, and managing personal finances. Implemented using Angular for the frontend and a Node.js/Express backend with a PostgreSQL database.'
  },
  { 
    id: 5, 
    title: 'AI-Powered Chatbot', 
    date: 'May 1, 2023', 
    author: 'Jae Park',
    content: 'Implemented a chatbot using natural language processing to provide customer support and answer queries. The chatbot was built with Python, utilizing libraries such as NLTK and deployed on AWS.'
  },
  { 
    id: 6, 
    title: 'Smart Home Automation System', 
    date: 'June 15, 2023', 
    author: 'Jae Park',
    content: 'Designed and developed a system to control home appliances remotely using a mobile app. The project involved IoT devices, MQTT protocol for communication, and a React Native app for user interface.'
  },
];


function BlogPost() {
  const { id } = useParams();
  const post = projects.find(post => post.id === parseInt(id));

  if (!post) {
    return <div>Project post not found</div>;
  }

  return (
    <>
    <Topbar />
      <Helmet>
          <title>Project Post</title>
      </Helmet>
    <Container>
      
      <Box textAlign="center" sx={{ width: '100%', margin: '0 auto' }}>
        <Typography variant="h5" component="div">
          {post.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {post.date} by {post.author}
        </Typography>
        </Box>
        <Divider sx={{ width: '100%', marginBottom: 2, marginTop: 2, backgroundColor: 'white' }} />
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Typography variant="body1" component="div">
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </Typography>
        </Box>
      
    </Container>
    </>
  );
}

export default BlogPost;
