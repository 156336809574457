import React from 'react';
import { Link } from 'react-router-dom';
import { Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from "../theme.js";

const projects = [
  { 
    id: 1, 
    title: 'E-Commerce Website Development', 
    date: 'January 15, 2023', 
    author: 'Jae Park',
    content: 'Developed a fully functional e-commerce website with user authentication, product listings, and a shopping cart. Utilized technologies such as React, Node.js, and MongoDB to create a seamless shopping experience.'
  },
  { 
    id: 2, 
    title: 'Mobile Fitness App', 
    date: 'February 10, 2023', 
    author: 'Jae Park',
    content: 'Created a mobile application that tracks fitness activities, provides workout plans, and integrates with wearable devices. The app was developed using React Native and Firebase for real-time data synchronization.'
  },
  { 
    id: 3, 
    title: 'Social Media Analytics Tool', 
    date: 'March 20, 2023', 
    author: 'Jae Park',
    content: 'Built a web application to analyze and visualize social media data, helping businesses improve their online presence. The tool leverages Python for data analysis and visualization libraries such as D3.js for interactive charts.'
  },
  { 
    id: 4, 
    title: 'Personal Finance Management System', 
    date: 'April 5, 2023', 
    author: 'Jae Park',
    content: 'Developed a system for tracking expenses, creating budgets, and managing personal finances. Implemented using Angular for the frontend and a Node.js/Express backend with a PostgreSQL database.'
  },
  { 
    id: 5, 
    title: 'AI-Powered Chatbot', 
    date: 'May 1, 2023', 
    author: 'Jae Park',
    content: 'Implemented a chatbot using natural language processing to provide customer support and answer queries. The chatbot was built with Python, utilizing libraries such as NLTK and deployed on AWS.'
  },
  { 
    id: 6, 
    title: 'Smart Home Automation System', 
    date: 'June 15, 2023', 
    author: 'Jae Park',
    content: 'Designed and developed a system to control home appliances remotely using a mobile app. The project involved IoT devices, MQTT protocol for communication, and a React Native app for user interface.'
  },
];


const CenteredContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
});

function ProjectList() {
  return (
    <ThemeProvider theme={theme}>
      <CenteredContainer>
        <List>
          {projects.map(post => (
            <ListItem key={post.id}>
              <ListItemText
                primary={
                  <Typography variant="h6" component={Link} to={`/projects/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {post.title}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {post.date} by {post.author}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CenteredContainer>
    </ThemeProvider>
  );
}

export default ProjectList;
