import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Button, IconButton, Modal, Backdrop } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Link } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import Resume from "./Resume"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
    fontSize: '1.25rem', 
  },
}));

function TopBar() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  
  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <Button component={Link} color="inherit" to="/">Home</Button>
            <Button component={Link} color="inherit" to="/blogs">Blogs</Button>
            <Button component={Link} color="inherit" to="/projects">Projects</Button>
            <StyledIconButton component="a" href="https://x.com/jaepark_13">
              <XIcon />
            </StyledIconButton>
            <StyledIconButton component="a" href="https://github.com/jaepark23">
              <GitHubIcon />
            </StyledIconButton>
            {/* <StyledIconButton component="a" onClick={handleOpenModal}>
              <DescriptionIcon />
            </StyledIconButton> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="resume-modal-title"
        aria-describedby="resume-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: false,
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          onClick: handleCloseModal
        }}
      >
        <Resume onClose={handleCloseModal} />
      </Modal> */}
      </>
  );
}

export default TopBar;
