import React from "react";
import Topbar from "../components/Topbar";
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import ProjectList from "../components/ProjectList"
import Divider from '@mui/material/Divider';

function ProjectPage() {
  return (
    <div>
    <CssBaseline />
      <Topbar />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <title>Projects</title>
        <h1> Projects </h1>
        <Divider sx={{ width: '100%', marginBottom: 2, backgroundColor: 'white' }} />
        Coming Soon

      </Container>
      </div>
  );
}

export default ProjectPage;
