import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomePage from './pages/HomePage';
import BlogPage from "./pages/BlogPage";
import ProjectPage from "./pages/ProjectPage";
import BlogPost from "./components/BlogPost";
import ProjectPost from "./components/ProjectPost";
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
          <Routes>
            <Route path="/" element={
              <>
                <Helmet>
                  <title>Home</title>
                  <meta name="description" content={"A website and blog highlighting Jae Park's experiences and insights on various topics including software engineering."} />
                </Helmet>
                <HomePage />
              </>
            } />
            <Route path="/blogs" element={
              <>
                <Helmet>
                ``  
                  <title>Blogs</title>
                  <meta name="description" content={"A website and blog highlighting Jae Park's experiences and insights on various topics including software engineering."} />
                </Helmet>
                <BlogPage />
              </>
            } />
            <Route path="/projects" element={
              <>
                <Helmet>
                  <title>Projects</title>
                  <meta name="description" content={"A website and blog highlighting Jae Park's experiences and insights on various topics including software engineering."} />
                </Helmet>
                <ProjectPage />
              </>
            } />
            <Route path="/blogs/:slug" element={
              <>
                <BlogPost />
              </>
            } />
            <Route path="/projects/:id" element={
              <>
                <ProjectPost />
              </>
            } />
          </Routes>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
