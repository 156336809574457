import React from "react";
import Topbar from "../components/Topbar";
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import BlogList from "../components/BlogList"
import Divider from '@mui/material/Divider';

function BlogPage() {
  return (
    <div>
    <CssBaseline />
      <Topbar />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <title>Blogs</title>
        <h1> Blogs </h1>
        <Divider sx={{ width: '100%', marginBottom: 2, backgroundColor: 'white' }} />
        <BlogList/>

      </Container>
      </div>
  );
}

export default BlogPage;
