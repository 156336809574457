import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import Topbar from "./Topbar";
import ReactMarkdown from 'react-markdown';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';

function BlogPost() {
  const { slug } = useParams();
  const [blog, setBlog] = useState();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    async function fetchData() {
      if(!slug) return;
      const response = await fetch(
        `${apiUrl}/blogs/${slug.toString()}`
      );
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      const blog = await response.json();
      if(!blog) {
        console.warn(`Blog with id ${slug} not found`);
        navigate("/");
        return;
      }
      setBlog(blog);
    }
    fetchData();
    return;
  }, [slug, navigate]);

  if (!blog) {
    return;
  }

  return (
    <>
    <Topbar />
    <Helmet>
        <title>{blog.title}</title> 
        <meta name="description" content={blog.content} />
      </Helmet>
    <Container>
      <Box textAlign="center" sx={{ width: '100%', margin: '0 auto' }}>
      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
        {blog.title}
      </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {blog.date} by {blog.author}
        </Typography>
        </Box>
        <Divider sx={{ width: '100%', marginBottom: 2, marginTop: 2, backgroundColor: 'white' }} />
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          sx={{ width: '100%', margin: '0 auto' }}
        >
          <Box sx={{ width: '75%' }}>
            <Typography variant="body1" component="div">
              <ReactMarkdown>{blog.content}</ReactMarkdown>
            </Typography>
          </Box>
        </Box>
    </Container>
    </>
  );
}

export default BlogPost;
