import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from "../theme.js";

const CenteredContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
});

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    async function getBlogs() {
      const response = await fetch(`${apiUrl}/blogs/`);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        console.error(message);
        return;
      }
      const blogs = await response.json();
      setBlogs(blogs);
    }
    getBlogs();
    return;
  }, [blogs.length]);

  return (
    <ThemeProvider theme={theme}>
      <CenteredContainer>
        <List>
          {blogs.map(post => (
            <ListItem key={post.slug}>
              <ListItemText
                primary={
                  <Typography variant="h6" component={Link} to={`/blogs/${post.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {post.title}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {post.date} by {post.author}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CenteredContainer>
    </ThemeProvider>
  );
}

export default BlogList;
